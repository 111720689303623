import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Helmet from "react-helmet"

import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';


const Kariera = ({ data }) => (
  <>
  <Helmet
  title={data.wordpressPage.acf.kariera_title_seo} 
  titleTemplate={data.wordpressPage.acf.kariera_title_seo}
  meta={[
    {
      name: `description`,
      content: data.wordpressPage.acf.kariera_description_seo, 
    },
    {
      property: `og:title`,
      content: data.wordpressPage.acf.kariera_title_seo,
    },
    {
      name: `thumbnail`,
      content: data.wordpressPage.acf.kariera_img_seo.localFile.url,
    },
    {
      property: `og:image`,
      content: data.wordpressPage.acf.kariera_img_seo.localFile.url, 
    },
    {
      property: `og:description`,
      content: data.wordpressPage.acf.kariera_description_seo,
    },
    {
      property: `og:type`,
      content: `website`,
    },
  ]}
/>
<div className="small-banner-content">
    <div style={{backgroundImage: `url(${data.wordpressPage.acf.kariera_banner_img.localFile.url})`}} className="background-small"></div>
          <div className="text-on-banner">  

          <ScrollAnimation animateOnce='true' animateIn='fadeInUp' delay='100' duration='0.5'>
          <h1 className='smallbanner-title'>{data.wordpressPage.acf.kariera_title}</h1>
          </ScrollAnimation>
      
        </div>
        <div className='right-bottom-curve-cont'>
          <div className="right-bottom-curve"></div>
        </div>
        </div>

        
<Layout lang="en" pageInfo={{ pageName: "kariera" }}>
<div className='career'>
      <Container>
        <h2 className='title-main'>{data.wordpressPage.acf.kariera_title_lower}<span className='dot'>.</span></h2>
          {data.wordpressPage.acf.stanowisko_rep.map((val, index) => 
            <Row className='kariera-single' key={index}>
            <Col className='winda-col' lg='4'>
              <div className='winda'>
                <div className='inner-left'>
                  <div className='img-box'>
                    <img src={val.img.localFile.url} alt={val.img.acf.alt_en}></img>
                    <div className='title-container'>
                      <h2 className='title'>{val.name}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg='8'>
              <div className='inner-right'>
                {val.tasks_rep.map((vala, indexa) => 
                  <>
                    <h3 key={indexa} className='list-title'>{vala.task_title}</h3>
                    <ul className='clearfix'>
                    {vala.task_list_rep.map((valb, indexb) =>           
                      <li key={indexb} className='list-item'>{valb.task_item}</li>                      
                    )}
                    </ul>
                  </>
                )}
                
              </div>
              <p className='more-info'>{val.dodatkowe_info}</p>
            </Col>
            <div className='kariera-sep'></div>
          </Row>
          )}
      </Container>
    </div>
</Layout> 
</>
)

export default Kariera
 export const query = graphql`
 query hyhygfgy {
  wordpressPage(title: {eq: "Kariera EN"}) {
    acf {
      kariera_title_seo
      kariera_description_seo
      kariera_img_seo {
        localFile {
          url
        }
        acf {
          alt_en
        }
      }
      kariera_banner_img {
        localFile {
          url
        }
        acf {
          alt_en
        }
      }
      kariera_title
      kariera_title_lower
      stanowisko_rep {
        img {
          localFile {
            url
          }
          acf {
            alt_en
          }
        }
        name
        dodatkowe_info
        tasks_rep {
          task_title
          task_list_rep {
            task_item
          }
        }
      }
    }
  }
}



 `
